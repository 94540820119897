import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import './footer.scss';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {t} = this.props;
        return (
            <footer className='footer-main'>
                <div className='container'>
                    <section className='footer-container'>
                    
                        <div className='footer-copyright'>
                            <p>  © {t('FEBRACIS')}  </p>
                        </div>

                    </section>
                </div>
            </footer>
        )
    }
}



export default withTranslation()(Footer);
