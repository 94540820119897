import React, { Component, Fragment } from 'react';
import AOS from 'aos';

import Header from '../../components/header/header';

import Footer from '../../components/footer/footer';

import 'aos/dist/aos.css';
import './home.scss';
import {confirmBaseUrl} from "../../utils/constants";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount(){
        AOS.init({
          duration : 2000
        });
        const [, coach, coachee] = window.location.pathname.split("/");
        if (coach && coachee) {
            fetch(confirmBaseUrl, {
                method: 'POST',
                mode: 'no-cors',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: `coach=${coach}&coachee=${coachee}`,
            });
            window.history.replaceState({}, "Bem vindo", "/");
        }
    }

    render() {
        const {toggleMenu, click} = this.props;

        return (
            <Fragment>
                <Header toggleMenu={toggleMenu} click={click}/>
    
                <Footer />
            </Fragment>
                
        )
    }
}

export default (Home);
