import React, { useState } from "react";

import './App.scss';
import AppRoutes from "./Routes";

export function App() {
  const [isToggleOn, setIsToggleOn] = useState(true);

  const handleClick = (isOpen) => {
    setIsToggleOn(isOpen);
  }

  return (
    <div className={`App ${!isToggleOn ? 'app-menu-active' : ''}`}>
      <AppRoutes toggleMenu={isToggleOn} click={handleClick} />
    </div>
  );
}

export default App;
