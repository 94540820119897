import React, { Component } from 'react';

import Icon from '../../utils/svg';

import './header.scss';
import Download from '../download/download';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: window.innerWidth };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateWindowDimensions);
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }
    
    updateWindowDimensions() {
       this.setState({ screenWidth: window.innerWidth });
    }

    render() {
        const { screenWidth } = this.state;

        return (
            <header className='header-main' id="header" >
                <div className='effect-yellow'></div>
                <div className='container'>
                    <section className='header-content'>
                        <Icon name='logo-scis' width={screenWidth >= 990 ? '216px' : '150px'} classCss='logo-scis'/>
                    </section>

                    <Download />
                    {/* <Intro/>  */}
                </div>
            </header>
        )
    }
}



export default (Header);
