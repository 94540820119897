import React from "react"
import Icon from '../../utils/svg';

import './headerTvFebracis.scss';

export default function HeaderTvFebracis({isMobile}) {
    return (
        <header className='header-content'>
            <Icon name='new-scis-log' width={isMobile ? 80 : 111} height={isMobile ? 28 : 39} />
        </header>
    );
}