import React from "react"
import { useTranslation } from 'react-i18next';

import Icon from '../../utils/svg';

import './downloadTvFebracis.scss';

export default function DownloadTvFebracis({isMobile}) {
    const {t} = useTranslation();
    return (
        <section className={`download-main-tvfebracis ${isMobile &&  'isMobile'}`}>
            <div className='container-download'>
                <div className='download-top'>
                    <div className='download-title'>
                        <h1>{t('HELLO')}</h1>
                        <Icon classCss='arrow-down' name='arrow-down' width={isMobile ? 20 : 30} height={isMobile ? 60 : 78} />
                    </div>
                    <p>{t('TO_ACCESS_FEBRACIS_TV_VIDEOS')}</p>
                </div>
                <div className='download-bottom'>
                    <span className='download-app'>{t('DOWNLOAD_SCIS_APP')}</span> 

                    <div className='download-buttons'>
                        <button className='btn-android'>
                            <a href='https://play.google.com/store/apps/details?id=com.scisapp' target="_blank" rel="noopener noreferrer">
                                <Icon name='icon-windows' width={isMobile ? 30 : 40} height={isMobile ? 30 : 40}/>
                                <span> Google Play </span>
                            </a> 
                        </button>

                        <button className='btn-apple'>
                            <a href='https://apps.apple.com/us/app/scis-app/id1370583933' target="_blank" rel="noopener noreferrer"> 
                                <Icon name='icon-apple' width={isMobile ? 30 : 40} height={isMobile ? 30 : 40}/>
                                <span> App Store </span>
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}