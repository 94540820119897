import React, { useEffect, useState } from "react"
import TvFebracis from '../../assets/img/TV-SCIS.png';

import './homeTvFebracis.scss';
import HeaderTvFebracis from "../../components/headerTvFebracis/headerTvFebracis";
import DownloadTvFebracis from "../../components/downloadTvFebracis/downloadTvFebracis";

export default function HomeTvFebracis() {
    const [isMobile, setIsMobile] = useState(window.innerWidth > 800 ? false : true);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth > 800 ? false : true)
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <div className='new-home-body'>
            <HeaderTvFebracis isMobile={isMobile} />
            <div className={`${!isMobile && 'home-body-container'}`}>
                <DownloadTvFebracis isMobile={isMobile} className='download' />
                {!isMobile && 
                    <div className='tv-febracis'>
                        <img src={TvFebracis} alt='Imagem dos vídeos da Tv Febracis na tela do smartphone' />
                    </div>
                }
            </div>
        </div>
    )
}