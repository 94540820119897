import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Icon from '../../utils/svg';

import ScreenDownload from '../../assets/img/computer-download.png';

import './download.scss';

class Download extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {t} = this.props;
        return (
            <section className='download-main' id='downloads'>
                <div className='container'>
                    <article className='download-text'>
                        <div className='download-content'>
                            <h2>  <b> {t('SUCCESS')} </b> </h2>
                            <h4> {t('REGISTER_REALIZATION')}
                                <br/> {t('OPEN_STORE')}
                                <br/> {t('DOWNLOAD_SCIS_APP')}
                             </h4>

                            <div className='download-button'>
                                <button className='btn-windows'>
                                    <a href='https://play.google.com/store/apps/details?id=com.scisapp' target="_blank" rel="noopener noreferrer">
                                        <Icon name='icon-windows' width={'22px'}/>
                                        <span> Google Play </span>
                                    </a> 
                                </button>

                                <button className='btn-apple'>
                                    <a href='https://apps.apple.com/us/app/scis-app/id1370583933' target="_blank" rel="noopener noreferrer"> 
                                        <Icon name='icon-apple' width={'22px'}/>
                                        <span> App Store </span>
                                    </a>
                                </button>
                            </div>
                        </div>

                        <div className='download-image'>
                            <img src={ScreenDownload} alt='Tela Scis'/>
                        </div>
                    </article>
                </div>
            </section>
        )
    }
}


export default withTranslation()(Download);