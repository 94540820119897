import React from "react";
import HomeTvFebracis from "./screens/homeTvFebracis/homeTvFebracis";
import Home from "./screens/home/home";

import {
    BrowserRouter as Router,
    Route,
    Routes,
  } from "react-router-dom";

const AppRoutes = ({toggleMenu, click}) => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home toggleMenu={toggleMenu} click={click}/>} />
                <Route path="/tvfebracis" element={<HomeTvFebracis />} />
                <Route path="*" element={<Home toggleMenu={toggleMenu} click={click}/>} />
            </Routes>
        </Router>
    )
}

export default AppRoutes;